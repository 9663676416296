<template>
  <div class="compta-rh-validation-frais">
    <div v-if="cantExport(componentName) && download == true">
      <font-awesome-icon
        icon="arrow-alt-circle-down"
        @click.prevent="downloadFacture()"
        class="icon-style-color mr-1"
        title="Télécharger la facture"
      />
    </div>

    <div
      v-if="
        cantExport(componentName) && download == true && item.id_avoir != null
      "
    >
      <font-awesome-icon
        icon="arrow-alt-circle-down"
        @click.prevent="downloadAvoir()"
        class="icon-style-color red-color-style mr-1"
        title="Télécharger l'avoir"
      />
    </div>

    <font-awesome-icon
      v-if="cantCreate(componentName) && !isSuperAdmin && editable"
      icon="file-code"
      @click.prevent="handleEditTemplate()"
      class="icon-style-color"
      title="Valider la facture"
    />
    <font-awesome-icon
      v-if="
        cantCreate(componentName) &&
          !isSuperAdmin &&
          download == true &&
          editable
      "
      icon="check-circle"
      class="icon-style-check-facture"
      title="facture validée"
    />
    <!-- facture to upload  template -->
    <b-modal
      no-close-on-backdrop
      ref="uploadFactureTemplateModal"
      id="uploadFactureTemplateModal"
      :title="
        getTemplateComptaRhToUpload && getTemplateComptaRhToUpload.name
          ? getTemplateComptaRhToUpload.name
          : ''
      "
      size="lg"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais "
    >
      <div
        class="iconClose"
        @click.prevent="hideModal('uploadFactureTemplateModal')"
      >
        <font-awesome-icon icon="times" />
      </div>
      <div class="message text-aligne-center">
        <div
          v-if="getLoadingGetDataTemplateComptaRh"
          class="chargement-message-calender "
        >
          Chargement en cours...
        </div>
        <div
          v-if="getErrorTemplateComptaRhDataToUpploadeed"
          class="error-message"
        >
          <div class="error">
            {{ getErrorTemplateComptaRhDataToUpploadeed }}
          </div>
        </div>
      </div>

      <div
        class="compta-rh-template-block-style"
        v-if="getTemplateComptaRhToUpload"
        id="hmt_block_id"
      >
        <div class="input-fields-style-for-template">
          <div class="form-actions mt-3 mb-5 text-aligne-center">
            <b-button
              @click="handleUpload"
              class="button-succes-style ml-2"
              size="sm"
              variant="success"
            >
              Valider
              <font-awesome-icon icon="arrow-alt-circle-right" class="ml-2" />
            </b-button>
            <div class="message text-aligne-center">
              <div v-if="loadingUpload" class="chargement-message-calender ">
                Chargement en cours...
              </div>
              <div v-if="errorUpload" class="error-message">
                <div class="error">{{ errorUpload }}</div>
              </div>
            </div>
          </div>
          <div class="fields-input">
            <div></div>
            <div>
              <b-form-group
                label="Date de Facture"
                label-for="title-template-swift"
                class=" input-modal-champ "
              >
                <date-picker
                  value-type="format"
                  format="DD-MM-YYYY"
                  type="date"
                  :value="getTemplateComptaRhToUpload.date"
                  class="inputDateTemplate"
                  @input="
                    changeFieldsValue({
                      key: 'date_id',
                      value: getTemplateComptaRhToUpload.date,
                      e: $event,
                      champName: 'date'
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'date_id'
                    })
                  "
                ></date-picker>
              </b-form-group>
              <b-form-group
                label="Date de reglement"
                label-for="title-template-swift"
                class=" input-modal-champ "
              >
                <date-picker
                  value-type="format"
                  format="DD-MM-YYYY"
                  type="date"
                  :value="getTemplateComptaRhToUpload.date_reglement"
                  class="inputDateTemplate"
                  @input="
                    changeFieldsValue({
                      key: 'date_reglement_id',
                      value: getTemplateComptaRhToUpload.date_reglement,
                      e: $event,
                      champName: 'date_reglement'
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'date_reglement_id'
                    })
                  "
                ></date-picker>
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Description "
                label-for="title-template-description"
                class=" input-modal-champ "
              >
                <b-form-textarea
                  id="title-template-description"
                  v-model="getTemplateComptaRhToUpload.description"
                  @input="
                    changeFieldsValue({
                      key: 'description_id',
                      value: getTemplateComptaRhToUpload.description
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'description_id'
                    })
                  "
                  rows="5"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <!-- <div>
              <b-form-group
                label="IBAN "
                label-for="title-template-iban"
                class=" input-modal-champ "
              >
                <b-form-input
                  autocomplete="off"
                  id="title-template-iban"
                  v-model="getTemplateComptaRhToUpload.iban"
                  @input="
                    changeFieldsValue({
                      key: 'iban_id',
                      value: getTemplateComptaRhToUpload.iban
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'iban_id'
                    })
                  "
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Swift "
                label-for="title-template-swift"
                class=" input-modal-champ "
              >
                <b-form-input
                  autocomplete="off"
                  id="title-template-swift"
                  v-model="getTemplateComptaRhToUpload.swift"
                  @input="
                    changeFieldsValue({
                      key: 'swift_id',
                      value: getTemplateComptaRhToUpload.swift
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'swift_id'
                    })
                  "
                  disabled
                ></b-form-input>
              </b-form-group>
            </div> -->
            <!-- Rib -->
            <b-col col xs="12" sm="12" md="12">
              <!-- Rib  --><b-form-group
                label="Rib"
                label-for="rib-template-select"
              >
                <multiselect
                  v-model="rib"
                  :options="getTemplateComptaRhToUpload.ribs"
                  :multiple="false"
                  label="rib"
                  track-by="rib"
                  required
                  class="b-form-select-raduis"
                  :showLabels="false"
                >
                </multiselect>
              </b-form-group>
            </b-col>

            <div class="message text-aligne-center">
              <div v-if="errorUploadRib" class="error-message-rib">
                <div class="error">{{ errorUploadRib }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="hmt_template_block_id"
          class="template-style"
          v-if="getTemplateComptaRhToUpload"
          v-html="getTemplateComptaRhToUpload.template"
        ></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);
moment.locale('fr');
import DatePicker from 'vue2-datepicker';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    year: { required: true },
    month: { required: true },
    item: { required: true },
    type: { required: true },
    componentName: { reduired: true },
    download: { default: false },
    editable: { default: true }
  },
  data() {
    return {
      loadingUpload: false,
      errorUpload: null,
      rib: null,
      errorUploadRib: null
    };
  },
  methods: {
    ...mapActions([
      'setDaraTemplateComptaRhUploded',
      'exportPdfFactureUploadedTypeComptaRh',
      'getComptaRhTemplateAndFiledsDataToUpdate',
      'uploadComptaRhTemplateInServieVisionFiliale'
    ]),
    async handleEditTemplate() {
      this.$refs['uploadFactureTemplateModal'].show();
      const response = await this.getComptaRhTemplateAndFiledsDataToUpdate(
        this.item
      );
      if (response) {
        let now = new Date();
        let dateFormate = moment(now).format('DD-MM-YYYY');
        if (
          this.getTemplateComptaRhToUpload.date == null ||
          this.getTemplateComptaRhToUpload.date == ''
        ) {
          document.getElementById('date_id').innerHTML = dateFormate;
          this.getTemplateComptaRhToUpload.date = dateFormate;
        }
        if (
          this.getTemplateComptaRhToUpload.date_reglement == null ||
          this.getTemplateComptaRhToUpload.date_reglement == ''
        ) {
          document.getElementById('date_reglement_id').innerHTML = dateFormate;

          this.getTemplateComptaRhToUpload.date_reglement = dateFormate;
        }
        if (
          this.getTemplateComptaRhToUpload.swift != null &&
          this.getTemplateComptaRhToUpload.iban != null &&
          this.getTemplateComptaRhToUpload.ribs.some(
            data => data.swift === this.getTemplateComptaRhToUpload.swift
          ) &&
          this.getTemplateComptaRhToUpload.ribs.some(
            data => data.iban === this.getTemplateComptaRhToUpload.iban
          )
        ) {
          this.rib = {
            swift: this.getTemplateComptaRhToUpload.swift,
            iban: this.getTemplateComptaRhToUpload.iban,
            rib:
              this.getTemplateComptaRhToUpload.iban +
              ' ' +
              this.getTemplateComptaRhToUpload.swift
          };
        } else if (
          !this.getTemplateComptaRhToUpload.ribs.some(
            data => data.swift === this.getTemplateComptaRhToUpload.swift
          ) &&
          !this.getTemplateComptaRhToUpload.ribs.some(
            data => data.iban === this.getTemplateComptaRhToUpload.iban
          ) &&
          this.getTemplateComptaRhToUpload.swift != null &&
          this.getTemplateComptaRhToUpload.iban != null
        ) {
          this.errorUploadRib =
            'Erreur ! RIB ( ' +
            this.getTemplateComptaRhToUpload.iban +
            ' ' +
            this.getTemplateComptaRhToUpload.swift +
            " ) n'est pas disponible , Merci de contacter l'administrateur .";
        }
      }
    },
    async handleUpload() {
      this.loadingUpload = true;
      this.errorUpload = null;
      this.errorUploadRib = null;
      if (this.rib == null) {
        this.loadingUpload = false;
        this.errorUpload = 'Une erreur est servenue ! RIB obligatoire';
      } else {
        let response = await this.uploadComptaRhTemplateInServieVisionFiliale({
          description: this.getTemplateComptaRhToUpload.description,
          iban: this.rib.iban,
          swift: this.rib.swift,
          item: this.item,
          date: this.getTemplateComptaRhToUpload.date,
          date_reglement: this.getTemplateComptaRhToUpload.date_reglement
        });

        if (response.succes) {
          this.loadingUpload = false;
          this.errorUpload = null;
          this.hideModal('uploadFactureTemplateModal');
        } else {
          this.errorUpload = response.error;
          this.loadingUpload = false;
        }
      }
    },
    changeFieldsValue(item) {
      if (item && item.e) {
        this.getTemplateComptaRhToUpload[item.champName] = item.e;
        document.getElementById(item.key).innerHTML = item.e;
      } else {
        document.getElementById(item.key).innerHTML = item.value;
      }
    },
    focusInFields(item) {
      var myElement = document.getElementById(item.key);
      var rect = myElement.getBoundingClientRect();
      document.getElementById('hmt_template_block_id').scrollTop =
        rect.top + window.pageYOffset;
    },
    resetModal() {
      this.loadingUpload = false;
      this.errorUpload = null;
      this.setDaraTemplateComptaRhUploded();
      this.errorUpload = null;
      this.errorUploadRib = null;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async downloadAvoir() {
      await this.exportPdfFactureUploadedTypeComptaRh({
        id: this.item.id_avoir
      });
    },
    async downloadFacture() {
      await this.exportPdfFactureUploadedTypeComptaRh({
        id: this.item.id
      });
    },
    async sendMail() {
      var bodyFormData = new FormData();
      bodyFormData.append('ids[0]', this.item.id);
      const response = await this.sendMailComptaRh(bodyFormData);
      if (response.succes) {
        if (this.getErrorOneDayComptaRh?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorOneDayComptaRh?.error,
            clickable: true,
            time: 0,
            message: this.getErrorOneDayComptaRh?.facture
          });
        }

        if (this.getSuccessSendMailsComptaRh?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'success',
            title: this.getSuccessSendMailsComptaRh?.success,
            clickable: true,
            time: 0,
            message: this.getSuccessSendMailsComptaRh?.facture
          });
        }
        if (this.getErrorInvalidElmailComptaRh?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorInvalidElmailComptaRh?.error,
            clickable: true,
            time: 0,
            message: this.getErrorInvalidElmailComptaRh?.facture
          });
        }
        if (this.getErrorHasntEmailComptaRh?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorHasntEmailComptaRh?.error,
            clickable: true,
            time: 0,
            message: this.getErrorHasntEmailComptaRh?.facture
          });
        }
      } else {
        this.flashMessage.show({
          status: 'error',
          title: 'ERROR',
          clickable: true,
          time: 0,
          message: this.getOtherErrorMail
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      'getTemplateComptaRhToUpload',
      'getLoadingGetDataTemplateComptaRh',
      'getErrorTemplateComptaRhDataToUpploadeed',
      'cantExport',
      'cantCreate',
      'getUserData',
      'cantSend',
      'getErrorHasntEmailComptaRh',
      'getErrorInvalidElmailComptaRh',
      'getSuccessSendMailsComptaRh',
      'getErrorOneDayComptaRh'
    ]),
    isSuperAdmin() {
      return this.getUserData?.role && this.getUserData.role == 'admin';
    }
  },
  components: {
    DatePicker
  }
};
</script>

<style lang="scss" scoped>
.compta-rh-validation-frais {
  display: flex;
}
.compta-rh-template-block-style {
  display: flex;
  .input-fields-style-for-template {
    width: 39%;
    background-color: #ecedec36;
    margin: 0% 2%;
    padding: 0% 1%;
    border-radius: 16px;
    .fields-input {
      display: inline;
      label {
        font-size: 13px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        text-align: start;
        color: #2a2a2a;
        margin-left: 0%;
        margin-top: 0px;
        margin-bottom: 5px;
      }
    }
  }
  .template-style {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 65px);
    width: 62%;
    padding-right: 6px;
  }
  .error-message-rib .error {
    color: #000;
    font-size: 16px;
    padding: 10px 20px;
    background: #f3beb8;
    border: 1px solid #f09898;
  }
}
.envelop-icon-container {
  background-color: #8d8cb7;
  border-radius: 15px;
  width: 13px;
  height: 13px;
}
.envelop-icon {
  width: 7px;
  color: #ececec;
  margin-left: 3px;
  margin-right: 3px;
}
.envelop-icon-container:hover {
  background-color: #4d4bac;
}
</style>
<style lang="scss">
.cutsom-modal-bootstrap .modal-dialog {
  top: 0%;
  max-width: 100%;
  margin: 5px;
}
.template-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.template-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d0cddc;
  border-radius: 10px;
}
.template-style::-webkit-scrollbar-thumb {
  background: #dadae4;
  border-radius: 7px;
}
.fields-input {
  label {
    font-size: 13px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 1%;
    margin-top: 0px;
    margin-bottom: 5px;
  }
  #title-template-description {
    border-radius: 15px;
  }
}
</style>
