<template>
  <div class="extration-bdd-frais">
    <b-button
      size="sm"
      class="button-export-style  ml-2"
      v-b-modal.extract-modal
      title="Télecharger  les factures"
    >
      <font-awesome-icon icon="cloud-download-alt" />
    </b-button>
    <!-- Extract BDD Modal -->
    <b-modal
      ref="extract-modal"
      id="extract-modal"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Générer un ' + typeModal"
      @hidden="hideModal('extract-modal')"
      modal-class="modal-extaction-bdd-frais"
    >
      <b-form-group
        id="fieldset-horizontal-type"
        label-cols-sm="5"
        label-cols-lg="3"
        content-cols-sm
        content-cols-lg="9"
        label="Type de fichier : "
        label-for="type-modal"
      >
        <b-form-select
          id="fieldset-horizontal-type"
          v-model="typeModal"
          :options="ListType"
          required
          class="b-form-select-raduis "
        ></b-form-select>
      </b-form-group>
      <!-- <div v-if="typeModal == 'xls'">
        <div>
          <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="4"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
            label="Modèle : "
            label-for="extract-modal"
          >
            <b-form-select
              id="extract-modal"
              v-model="selectedModel"
              :options="ListModelToExtract"
              required
              class="b-form-select-raduis "
              disabled
            ></b-form-select>
          </b-form-group>
        </div>
      </div> -->
      <div v-if="typeModal === 'xls'">
        <b-form-group
          label="colonnes a afficher dans l'xls:"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="flavour-1"
            stacked
            switches
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
      <div class="form-modal-custom-style mt-2">
        <div class="messageError">
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button class="button-valide-style " @click="handleExportFile">
            <span>
              Générer
              <div
                v-if="getLoadingExtactionFriasVisionComptaRh"
                class="loading ml-2"
              >
                <div class="spinner-border" role="status"></div></div
            ></span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Extract BDD Modal -->
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    filterType: {
      default: null
    },
    filteryear: { reduired: true },
    filterMonth: { reduired: true },
    dataToUse: { required: true },
    filter: { required: true }
  },
  data() {
    return {
      selectedModel: 'standard',
      ListYear: [],
      error: null,
      selected: ['nom', 'numero', 'montant', 'statut', 'date'],
      options: [
        { text: 'Nom', value: 'nom' },
        { text: 'Numéro facture', value: 'numero' },
        { text: 'Montant', value: 'montant' },
        { text: 'Statut', value: 'statut' },
        { text: 'Date', value: 'date' }
      ],
      ListMonth: [
        { value: null, text: 'Tous' },
        { value: 'janvier', text: 'janvier' },
        { value: 'février', text: 'février' },
        { value: 'mars', text: 'mars' },
        { value: 'avril', text: 'avril' },
        { value: 'mai', text: 'mai' },
        { value: 'juin', text: 'juin' },
        { value: 'juillet', text: 'juillet' },
        { value: 'aout', text: 'aout' },
        { value: 'septembre', text: 'septembre' },
        { value: 'octobre', text: 'octobre' },
        { value: 'novembre', text: 'novembre' },
        { value: 'décembre', text: 'décembre' }
      ],
      ListModelToExtract: [
        { value: 'standard', text: 'Exports Standards' },
        { value: 'vertical', text: 'Verticale' },
        { value: 'horizontal', text: 'Horizontale' }
      ],
      ListType: [
        { value: 'xls', text: 'xls' },
        { value: 'pdf', text: 'pdf' }
      ],
      typeModal: 'xls'
    };
  },
  methods: {
    ...mapActions([
      'exportExcelFraisVisionComptaRhTh',
      'exportAllPdfFraisVisionComptaRhTh'
    ]),
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.selectedModel = 'standard';
      this.typeModal = 'xls';
      this.error = null;
      this.selected = ['nom', 'numero', 'montant', 'statut', 'date'];
    },
    async handleExportFile() {
      if (this.typeModal == 'xls') {
        var bodyFormData = new FormData();
        bodyFormData.append('type', this.filterType);
        bodyFormData.append('year', this.filteryear);
        for (let i = 0; i < this.selected.length; i++) {
          bodyFormData.append('columns[' + [i] + ']', this.selected[i]);
        }
        let dataToUseBE = [];
        dataToUseBE = this.dataToUse.filter(row => row.check == true);
        // if (dataToUseBE.length == 0) {
        //   dataToUseBE = this.dataToUse;
        // }
        if (dataToUseBE.length > 0) {
          let indexToUse = 0;
          for (let j = 0; j < dataToUseBE.length; j++) {
            bodyFormData.append('ids[' + [indexToUse] + ']', dataToUseBE[j].id);
            indexToUse = indexToUse + 1;
          }
        } else {
          Object.keys(this.filter).forEach(key => {
            if (this.filter[key] != null && this.filter[key] != 'type') {
              bodyFormData.append(key, this.filter[key]);
            }
          });
        }

        if (this.selected.length === 0) {
          this.error = 'selectionner au moins une colonne';
        } else {
          const response = await this.exportExcelFraisVisionComptaRhTh(
            bodyFormData
          );
          if (response) {
            this.hideModal('extract-modal');
          }
        }
      } else {
        var bodyFormData = new FormData();
        bodyFormData.append('type', this.filterType);

        let dataToUseBE = [];
        dataToUseBE = this.dataToUse.filter(row => row.check == true);
        if (dataToUseBE.length == 0) {
          dataToUseBE = this.dataToUse;
        }
        let indexToUse = 0;
        for (let j = 0; j < dataToUseBE.length; j++) {
          if (dataToUseBE[j] && dataToUseBE[j].valid != false) {
            bodyFormData.append('ids[' + [indexToUse] + ']', dataToUseBE[j].id);
            indexToUse = indexToUse + 1;
          }
        }
        const response = await this.exportAllPdfFraisVisionComptaRhTh(
          bodyFormData
        );
        if (response) {
          this.hideModal('extract-modal');
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getLoadingExtactionFriasVisionComptaRh'])
  },
  mounted() {
    var courantYear = moment().year();
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
  }
};
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.align-item-extract-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-extaction-bdd-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
</style>
