<template>
  <div class="global-validation-frais">
    <b-button
      size="sm"
      class="button-export-style  ml-2"
      @click.prevent="handleValidGlobal(false)"
      title="Valider des factures"
    >
      <font-awesome-icon icon="check-double" class="file-medical"
    /></b-button>
    <!-- facture to upload  template -->
    <b-modal
      no-close-on-backdrop
      ref="uploadFactureTemplateModal"
      id="uploadFactureTemplateModal"
      :title="
        getTemplateComptaRhToUpload && getTemplateComptaRhToUpload.name
          ? getTemplateComptaRhToUpload.name
          : ''
      "
      size="lg"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais "
    >
      <div
        class="iconClose"
        @click.prevent="hideModal('uploadFactureTemplateModal')"
      >
        <font-awesome-icon icon="times" />
      </div>
      <div class="message text-aligne-center">
        <div
          v-if="getLoadingGetDataTemplate"
          class="chargement-message-calender "
        >
          Chargement en cours...
        </div>
        <div v-if="getErrorTemplateDataToUpploadeed" class="error-message">
          <div class="error">{{ getErrorTemplateDataToUpploadeed }}</div>
        </div>
      </div>

      <div
        class="global-template-block-style"
        v-if="getTemplateComptaRhToUpload"
        id="hmt_block_id"
      >
        <div class="input-fields-style-for-template">
          <div class="form-actions mt-3 mb-5 text-aligne-center">
            <b-button
              @click="handleUpload"
              class="button-succes-style ml-2"
              size="sm"
              variant="success"
            >
              Valider
              <font-awesome-icon icon="arrow-alt-circle-right" class="ml-2" />
            </b-button>
            <div class="message text-aligne-center">
              <div v-if="loadingUpload" class="chargement-message-calender ">
                Chargement en cours...
              </div>
              <div v-if="errorUpload" class="error-message">
                <div class="error">{{ errorUpload }}</div>
              </div>
            </div>
          </div>
          <div class="fields-input">
            <div></div>
            <div>
              <b-form-group
                label="Date de Facture"
                label-for="title-template-swift"
                class=" input-modal-champ "
              >
                <date-picker
                  value-type="format"
                  format="DD-MM-YYYY"
                  type="date"
                  :value="date"
                  class="inputDateTemplate"
                  @input="
                    changeFieldsValue({
                      key: 'date_id',
                      value: date,
                      e: $event,
                      champName: 'date'
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'date_id'
                    })
                  "
                ></date-picker>
              </b-form-group>
              <b-form-group
                label="Date de reglement"
                label-for="title-template-swift"
                class=" input-modal-champ "
              >
                <date-picker
                  value-type="format"
                  format="DD-MM-YYYY"
                  type="date"
                  :value="date_reglement"
                  class="inputDateTemplate"
                  @input="
                    changeFieldsValue({
                      key: 'date_reglement_id',
                      value: date_reglement,
                      e: $event,
                      champName: 'date_reglement'
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'date_reglement_id'
                    })
                  "
                ></date-picker>
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Description "
                label-for="title-template-description"
                class=" input-modal-champ "
              >
                <b-form-textarea
                  id="title-template-description"
                  v-model="getTemplateComptaRhToUpload.description"
                  @input="
                    changeFieldsValue({
                      key: 'description_id',
                      value: getTemplateComptaRhToUpload.description
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'description_id'
                    })
                  "
                  rows="5"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="IBAN "
                label-for="title-template-iban"
                class=" input-modal-champ "
              >
                <b-form-input
                  autocomplete="off"
                  id="title-template-iban"
                  v-model="getTemplateComptaRhToUpload.iban"
                  @input="
                    changeFieldsValue({
                      key: 'iban_id',
                      value: getTemplateComptaRhToUpload.iban
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'iban_id'
                    })
                  "
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Swift "
                label-for="title-template-swift"
                class=" input-modal-champ "
              >
                <b-form-input
                  autocomplete="off"
                  id="title-template-swift"
                  v-model="getTemplateComptaRhToUpload.swift"
                  @input="
                    changeFieldsValue({
                      key: 'swift_id',
                      value: getTemplateComptaRhToUpload.swift
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'swift_id'
                    })
                  "
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
        <div
          id="hmt_template_block_id"
          class="template-style"
          v-if="getTemplateComptaRhToUpload"
          v-html="getTemplateComptaRhToUpload.content"
        ></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    year: { required: true },
    month: { required: true },
    type: { required: true },
    dataToUse: { required: true }
  },
  data() {
    return {
      loadingUpload: false,
      errorUpload: null,
      date: null,
      date_reglement: null
    };
  },
  methods: {
    ...mapActions([
      'uploadGlobalTemplateForComptaRh',
      'getGlobalTemplateAndFiledsDataToUpdateForComptaRh',
      'setDaraTemplateComptaRhUploded'
    ]),
    async handleValidGlobal() {
      this.$refs['uploadFactureTemplateModal'].show();
      let now = new Date();
      let dateFormate =
        now.getDate() + '-' + (now.getMonth() + 1) + '-' + now.getFullYear();
      dateFormate = moment(dateFormate, 'DD-MM-YYYY').format('DD-MM-YYYY');
      this.date = dateFormate;
      this.date_reglement = dateFormate;

      const response = await this.getGlobalTemplateAndFiledsDataToUpdateForComptaRh(
        {
          type: this.computedRole
        }
      );
    },
    async handleUpload() {
      if (
        this.getGlobaleTemplateCactus &&
        this.getGlobaleTemplateCactus.rib != null
      ) {
        this.loadingUpload = true;
        this.errorUpload = null;
        var bodyFormData = new FormData();
        bodyFormData.append(
          'description',
          this.getTemplateComptaRhToUpload.description
        );
        bodyFormData.append('iban', this.getTemplateComptaRhToUpload.iban);
        bodyFormData.append('rib', this.getTemplateComptaRhToUpload.rib);
        bodyFormData.append('swift', this.getTemplateComptaRhToUpload.swift);
        bodyFormData.append('date', this.date);
        bodyFormData.append('date_reglement', this.date_reglement);
        let dataToUse = this.dataToUse.filter(row => row.check == true);
        let indexuser = 0;
        for (let i = 0; i < dataToUse.length; i++) {
          bodyFormData.append('ids[' + [indexuser] + ']', dataToUse[i].id);

          indexuser = indexuser + 1;
        }
        const response = await this.uploadGlobalTemplateForComptaRh(
          bodyFormData
        );

        if (response.succes) {
          this.loadingUpload = false;
          this.errorUpload = null;
          this.hideModal('uploadFactureTemplateModal');
          let errorMessage =
            response.message.errors && response.message.errors > 0
              ? '\n' +
                '<div>Factures non validé : ' +
                response.message.errors +
                ' <span style="font-size: 18px;border-radius: 50%;background-color: #ff0000d9;padding: 1px 7px;color: #fff;" title="Total zéro">!</span></div>'
              : '';
          let text =
            '<div style="dispaly:flex"><div>Factures validé avec succès  : ' +
            response.message.success +
            '\n' +
            '\n' +
            '</div>' +
            errorMessage;

          this.$alert('', text, 'success').then(() => {
            this.$emit('reloadData');
          });
        } else {
          this.errorUpload = response.error;
          this.loadingUpload = false;
        }
      } else {
        this.errorUpload = 'Une erreur est servenue ! RIB obligatoire';
      }
    },
    changeFieldsValue(item) {
      this[item.champName] = item.e;
    },
    focusInFields(item) {
      // var myElement = document.getElementById(item.key);
      // var rect = myElement.getBoundingClientRect();
      // document.getElementById('hmt_template_block_id').scrollTop =
      //   rect.top + window.pageYOffset;
    },
    resetModal() {
      this.loadingUpload = false;
      this.errorUpload = null;
      this.setDaraTemplateComptaRhUploded();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    }
  },
  computed: {
    ...mapGetters([
      'getTemplateComptaRhToUpload',
      'getLoadingGetDataTemplate',
      'getErrorTemplateDataToUpploadeed'
    ]),
    computedCheckFraisBureauEtude() {
      for (let i = 0; i < this.getFraisBureauEtude.length; i++) {
        if (this.getFraisBureauEtude[i].check == true) {
          return true;
        }
      }
      return false;
    },
    computedRole() {
      switch (this.type) {
        default:
          return this.type;
      }
    }
  },
  components: {
    DatePicker
  }
};
</script>

<style lang="scss" scoped>
.global-template-block-style {
  display: flex;
  .input-fields-style-for-template {
    width: 39%;
    background-color: #ecedec36;
    margin: 0% 2%;
    padding: 0% 1%;
    border-radius: 16px;
    .fields-input {
      display: inline;
      label {
        font-size: 13px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        text-align: start;
        color: #2a2a2a;
        margin-left: 0%;
        margin-top: 0px;
        margin-bottom: 5px;
      }
    }
  }
  .template-style {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 65px);
    width: 62%;
    padding-right: 6px;
  }
}
</style>
